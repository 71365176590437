// --------------------------------------------------
// SASS mixins
// --------------------------------------------------

/* Transition mixins */
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

/* Placeholder mixins */
@mixin placeholder {
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}

/* BOX SHADOW */

/*@mixin box-shadow($x-axis: 0, $y-axis: 1px, $blur: 2px, $color: $default) {
  box-shadow: $x-axis $y-axis $blur $color;
}*/