	.profile-left-content {
		max-width: 720px;
		width: 100%;
		@media screen and (max-width:$ipad-landscape) {
			padding: 0 10px;
		}
		@media screen and (max-width:$mobile-small) {
			padding: 0;
		}
		.your-profile-info {
			background-color: $mint-color;
			padding: 13px 98px 20px 51px;

			@media screen and (max-width:$large-m) {
				padding: 25px 50px 25px 30px;
			}
			@media screen and (max-width:$ipad-landscape) {
				padding: 22px 43px 25px 20px;
			}
			@media screen and (max-width:$mobile-small)
			{
				width: calc(100% - 24px);
				margin: 0 auto;
			}
			h2 {
				font-size: 18px;
				line-height: 22px;
				color: $black;
				font-family: $arial;
				font-weight: $font-normal;
				@media screen and (max-width:$large-m) {
					margin-top: 0;
				}
				@media screen and (max-width:$mobile-small)
				{
					font-size: 15px;
					line-height: 28px;
				}
			}

			p {
				font-size: 16px;
				line-height: 20px;
				color: $light-black;
				font-family: $arial;
				font-weight: $font-normal;
				@media screen and (max-width:$large-m) {
					margin-bottom: 0;
				}
				@media screen and (max-width:$mobile-small)
				{
					font-size: 15px;
					line-height: 20px;
					margin-top: 0;
				}
			}
		}
	}

	.profile-form-block {
		padding-top: 5px;
		.upload-profile-photo {
			@media screen and (max-width:$mobile-small) {
					padding: 0 30px 16px;
					margin-bottom: 15px;
					position: relative;
			}
			h3 {
				font-size: 22px;
				line-height: 26px;
				color: $black;
				font-family: $gibson;
				font-weight: $font-semi-bold;
				margin-bottom: 18px;
				@media screen and (max-width:$mobile-small)
				{
					font-size: 15px;
					line-height: 18px;
					margin: 8px 0 12px 0;
				}
			}

			.upload-img {
				padding-left: 20px;
				@media screen and (max-width:$mobile-small) {
					width: calc(100% - 96px);
					padding-left: 15px;
				}
				>  span {
					color: $light-black;
					font-family: $arial;
					font-weight: $font-normal;
					font-size: 16px;
					line-height: 20px;
					display: block;
					margin-bottom: 12px;
					@media screen and (max-width:$mobile-small) {
						font-size: 15px;
					}
					@media screen and (max-width:$small-x) {
						font-size: 13px;
					}
				}
				.empty-field {
						font-size: 14px;
						line-height: 18px;
						color: $red;
						font-family: $arial;
						font-weight: $font-normal;
				}
			}
			.prof-user-img {
				width: 123px;
				height: 123px;
				border-radius: 50%;
				position: relative;
				@media screen and (max-width:$mobile-small) {
						width: 96px;
						height: 96px;
				}
				img {
					width: 123px;
					height: 123px;
					position: absolute;
					height: 100%;
					width: 100%;
					left: 50%;
					top: 50%;
					transform: translate(-50%,-50%);
					-webkit-transform: translate(-50%,-50%);
					border-radius: 50%;
					@media screen and (max-width:$mobile-small) {
						width: 96px;
						height: 96px;
					}
				}
				.loader {
					position: absolute;
				}
			}
			&:after
			{
				@media screen and (max-width:$mobile-small) {
					content: "";
					display: block;
					width: calc(100% - 36px);
					height: 1px;
					background-color: $off-white;
					bottom: 0;
					top: auto;
					position: absolute;
					left: 0;
					right: 0;
					margin: 0 auto;			
				}
			}
		}

		.form-block {
			padding-top: 35px;
			@media screen and (max-width:$mobile-small) {
				padding: 0 35px;	
			}
			.form-group
			{
				&.one-by-two
				{
					@media screen and (max-width:$mobile-small) {
						width: 100%;
					}	
				}
			}
			.button-group {
				padding-top: 30px;

				@media screen and (max-width:$large-m) {
					padding-top: 20px;
				}
				@media screen and (max-width:$mobile-small)
				{
					padding-top: 8px;
				}

				.btn {
					min-width: 213px;
					@media screen and (max-width:$mobile-small)
					{
						min-width: 156px;
					}
					
					&:after {
						width: 213px;
						background-repeat: repeat-x;
						background-size: contain;
					}
				}
			}
		}
		
	}

/*IE CSS*/

/*IE Support CSS */
@media all and (-ms-high-contrast:none) {
	.name-of-heading {
		display: inline-block;
	}

	.name-of-heading img,
	.name-of-heading h1 {
		display: inline-block;
		vertical-align: middle;
	}
}

/*Edge Support CSS */
@supports (-ms-ime-align: auto) {
	.name-of-heading {
		display: inline-block;
	}

	.name-of-heading img,
	.name-of-heading h1 {
		display: inline-block;
		vertical-align: middle;
	}
}