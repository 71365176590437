.announcements-main {
	@media screen and (max-width:$mobile-small) {
		padding: 0 23px;
	}

	.fileter-block {
		@media screen and (max-width:$mobile-small) {
			padding: 0 0 15px;
		}
	}

	.details-head-block {
		h3 {
			font-size: 16px;
			line-height: 28px;
			margin: 0;
			color: $panton-gray;
			font-weight: $font-semi-bold;
			font-family: $gibson;
		}

		h4 {
			font-size: 16px;
			line-height: 20px;
			margin: 0 0 0 45px;
			color: $light-black;
			font-family: $arial;
			font-weight: $font-normal;

			@media screen and (max-width:$ipad-portrait) {
				margin: 0 0 0 15px;
			}

			@media screen and (max-width:$small-x) {
				width: 100%;
				margin: 0;
			}

			@media (max-height: $mob-landscape-414) and (orientation: landscape) {
				width: auto;
			}
		}
	}

	.anouncements-top-block {
		width: 100%;
		padding-top: 48px;

		@media screen and (max-width: $ipad-landscape) {
			padding-top: 30px;
		}

		.anouncements-check {
			padding: 0;
			margin: 0 -18px;

			@media screen and (max-width: $ipad-landscape) {
				margin: 0 -10px;
			}

			justify-content: space-around;

			li {
				width: calc(25% - 36px);
				box-shadow: 0px 4px 16px $gray-light-rgb;
				background-color: $white;
				align-items: center;
				display: inherit;
				margin-bottom: 8px;

				@media screen and (max-width:$ipad-landscape) {
					width: calc(25% - 20px);
				}

				@media screen and (max-width:$ipad-portrait) {
					width: calc(50% - 20px);
					margin: 10px 0;
				}

				@media screen and (max-width:$mobile-small) {
					width: calc(100% - 20px);
				}

				a {
					width: 100%;
					padding: 28px 15px;
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					text-decoration: none;

					@media screen and (max-width: $ipad-landscape) {
						padding: 20px 15px;
					}

					span {
						width: 100%;
						text-align: center;
						font-size: 18px;
						line-height: 28px;
						color: $panton-gray;
						font-family: $gibson;
						font-weight: $font-semi-bold;
						margin-top: 5px;

						@media screen and (max-width: $mobile-small) {
							font-size: 16px;
							line-height: 22px;
						}
					}

					img,
					svg {
						width: 29px;

						@media screen and (max-width: $mobile-small) {
							width: 24px;
						}
					}
				}

				&.active {
					background-color: $sky-blue;

					a {
						span {
							color: $white;
						}

						svg {
							.shp0 {
								fill: $white;
							}
						}
					}
				}
			}
		}
	}

	.anouncements-form {
		padding-top: 40px;

		@media (max-height: $mob-landscape-414) and (orientation: landscape) {
			padding-top: 20px;
		}

		form {
			max-width: 980px;
			width: 100%;

			.form-group {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				max-width: 635px;
				width: 100%;
				margin-bottom: 12px;

				input[type="text"] {
					max-width: 481px;
					width: 100%;

					&:focus {
						border-color: $sky-blue;
					}
				}

				label {
					font-size: 18px;
					line-height: 22px;
					font-family: $arial;
					font-weight: $font-normal;
					padding-right: 19px;
					width: 100%;
					max-width: 154px;
					margin-bottom: 0;

					@media screen and (max-width:$ipad-portrait) {
						margin-bottom: 5px;
					}

					@media screen and (max-width:$mobile-small) {
						font-size: 16px;
					}

					@media (max-height: $mob-landscape-414) and (orientation: landscape) {
						font-size: 16px;
					}
				}
			}

			.text-edit-bar {
				margin-bottom: 22px;
				margin: 34px 0 22px;

				label {
					background-color: $cadet-blue;
					color: $white;
					padding: 11px 19px;
					margin-bottom: 24px;
					font-size: 18px;
					line-height: 22px;
					font-family: $arial;
					font-weight: $font-normal;
				}

				textarea {
					padding: 13px 34% 11px 11px;
					color: $light-black;
					font-size: 14px;
					line-height: 24px;
					font-family: $arial;
					font-weight: $font-normal;
					font-style: italic;
					height: 303px;

					@media screen and (max-width:$mobile-small) {
						height: 200px;
					}
				}

				.textarea-block {
					position: relative;

					> img {
						position: absolute;
						/*height: 78%;*/
						width: 29%;
						right: 40px;
						top: 50%;
						transform: translateY(-50%);
						-webkit-transform: translateY(-50%);

						@media screen and (max-width: $ipad-portrait) {
							right: 20px;
						}

						@media screen and (max-width: $small-medium) {
							right: 10px;
						}
					}
				}
			}
		}
	}

	.announcements-filter {
		position: relative;
		border-bottom: 1px solid $off-white;
		padding: 18px 10px 8px 0;

		@media screen and (max-width: $large-m) {
			padding: 30px 0 15px 0;
		}

		@media (max-height: $mob-landscape-414) and (orientation: landscape) {
			padding: 20px 0 10px 0;
		}

		h4 {
			font-size: 16px;
			line-height: 20px;
			font-family: $arial;
			font-weight: $font-normal;
			margin: 5px 0;
		}

		.filter-right {
			margin-left: auto;

			@media screen and (max-width: $ipad-landscape) {
				margin-left: 0;
			}

			.select-box {
				margin: 5px 0 0;

				&.location {
					width: 217px;
					margin-right: 23px;

					@media screen and (max-width: $large-mx) {
						margin-right: 10px;
					}

					@media screen and (max-width: $large-m) {
						width: 200px;
					}
				}

				&.prod-type {
					width: 282px;
					margin-right: 31px;

					@media screen and (max-width: $large-mx) {
						margin-right: 10px;
					}

					@media screen and (max-width: $large-m) {
						width: 270px;
					}
				}

				&.bulk-action {
					width: 146px;
				}

				span {
					font-size: 18px;
					line-height: 22px;
					padding: 8px 18px;
				}

				.list {
					top: 36px;
				}
			}
		}

		&:after {
			content: "";
			display: block;
			width: 100%;
			height: 1px;
			background-color: #F5F5F5;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.16);
		}
	}

	.pro-title {
		padding: 15px 30px 15px 0;
		margin-top: 75px;

		@media screen and (max-width: $large-mx) {
			padding: 15px 15px 15px 0;
			margin-top: 20px;
		}

		&:before {
			box-shadow: none;
		}

		&:after {
			box-shadow: none;
		}

		.name-of-heading {
			img {
				width: 42px;

				@media screen and (max-width: $ipad-portrait) {
					width: 26px;
				}
			}
		}

		.mobile-filter {
			margin-left: auto;
		}
	}

	.clients-table {
		.table-responsive {
			padding-top: 3px;
		}

		.btn-block {
			padding-top: 30px;

			.btn {
				min-width: 330px;
				margin-bottom: 0;
				padding: 8px 15px 4px;

				@media screen and (max-width:$large-mx) {
					min-width: 250px;
					padding: 8px 15px 4px;
				}

				@media screen and (max-width: $ipad-portrait) {
					min-width: auto;
				}

				&:after {
					background-size: contain;
					background-repeat: repeat-x;
					width: auto;
				}
				span {
					@media screen and (max-width:$large-mx) {
						font-size: 16px;
						line-height: 36px;
					}
					@media screen and (max-width:$mobile-small) {
						line-height: 30px;
					}
				}
			}
		}

		&.table-outer {
			.table-responsive {
				table {
					thead {
						tr {
							th {
								@media screen and (max-width: $large-mx) {
									font-size: 16px;
									line-height: 22px;
								}
								&:nth-child(1) {
									@media screen and (max-width: $large-mx) {
										width: 30%;
									}
									@media screen and (max-width: $large) {
										width: 410px;
									}
								}

								&:nth-child(2) {
									@media screen and (max-width: $large-mx) {
										width: 25%;
									}
									@media screen and (max-width: $large) {
										width: 435px;
									}
								}

								&:nth-child(3) {
									@media screen and (max-width: $large-mx) {
										width: 25%;
									}
									@media screen and (max-width: $large) {
										width: 340px;
									}
								}

								&:nth-child(4) {
									@media screen and (max-width: $large-mx) {
										width: 20%;
									}
									@media screen and (max-width: $large) {
										width: 150px;
									}
								}
							}
						}
					}
					tbody {
						tr {
							td {
								@media screen and (max-width: $large-mx) {
									font-size: 16px;
									line-height: 20px;
								}
								&:nth-child(1) {
									h3 {
										@media screen and (max-width: $large-mx) {
											font-size: 16px;
											line-height: 20px;
										}
									}
								}
							}
						}
					}
				}
			}
		}


	}

}