.admin-resources-main {
	.fileter-block {
		padding-right: 32px;
		@media screen and (max-width:$large-m) {
			padding-right: 20px;
		}
		@media screen and (max-width:$ipad-portrait) {
			padding-right: 0;
		}
		@media screen and (max-width:$mobile-small) {
			padding-right: 23px;
		}
		.select-box {
			max-width: 282px;
			@media screen and (max-width: $large-m) {
				max-width: 240px;
			}
			> span {
				padding: 7px 25px;
				font-size: 18px;
				line-height: 22px;
				@media screen and (max-width: $large-m) {
					padding: 7px 15px;
				}
			}
			.list {
				top: 37px;
				width: calc(100% + 2px);
				left: -1px;
			}
			&:hover {
				.list {
					box-shadow: 0 15px 16px rgba(69, 91, 99, .08);
				}
			}
		}
		.search-sort-block {
			@media screen and (max-width: $ipad-portrait) {
				margin-top: 5px;
				margin-left: auto;
				margin-right: 0;
			}

			.common-btn-blue {
				min-width: 127px;
				margin-bottom: 0;
				padding: 5px 15px;

				span {
					font-size: 18px;
					line-height: 28px;
				}
			}

			@media screen and (max-width:$mobile-small) {
				margin-left: auto;
			}

			.mobile-filter {
				margin-left: auto;
			}
		}
		@media screen and (max-width:$mobile-small) {
			padding-bottom: 15px;
		}
	}
	.resources-table {
		padding-top: 30px;
		.table-responsive {
			table {
				thead {
					th {
						&:nth-child(1) {
							width: 320px;
							@media screen and (max-width:$large-mx) {
								width: 30%;
							}
							@media screen and (max-width:$large) {
								width: 320px;
							}
						}
						&:nth-child(2) {
							width: 270px;
							@media screen and (max-width:$large-mx) {
								width: 25%;
							}
							@media screen and (max-width:$large) {
								width: 270px;
							}
						}
						&:nth-child(3) {
							width: 260px;
							@media screen and (max-width:$large-mx) {
								width: 15%;
							}
							@media screen and (max-width:$large) {
								width: 260px;
							}
						}
						&:nth-child(4) {
							width: 290px;
							text-align: left;
							@media screen and (max-width:$large-mx) {
								width: 15%;
							}
							@media screen and (max-width:$large) {
								width: 290px;
							}
						}
						&:nth-child(5) {
							width: 190px;
							text-align: left;

							@media screen and (max-width:$large-mx) {
								width: 15%;
							}

							@media screen and (max-width:$large) {
								width: 190px;
							}
						}
					}
				}
				tbody {
					tr {
						td {
							vertical-align: middle;
							font-size: 16px;
							line-height: 20px;
							color: $light-black;
							.checkbox-cust {
								vertical-align: top;
							}
							.name-edit {
								width: calc(100% - 18px);
								padding-left: 0;

								.right-detail {
									h3 {
										display: inline-block;
										margin-bottom: 0;
										font-size: 16px;
										line-height: 20px;
									}

									.action {
										font-size: 16px;
										line-height: 20px;
										display: none;
									}

									> a {
										display: inline-block;
										text-decoration: none;
										position: relative;
										color: $black;
										font-size: 16px;
										line-height: 20px;
										font-family: $arial;
										font-weight: $font-normal;
										padding-left: 15px;

										&:before {
											content: "";
											display: inline-block;
											color: $black;
											background-color: $black;
											position: absolute;
											height: 18px;
											width: 1px;
											top: 50%;
											left: 8px;
											transform: translateY(-50%);
											-webkit-transform: translateY(-50%);
										}

										&:hover {
											color: $sky-blue;
										}
									}
								}
							}

							&:nth-child(3) {
								> span {
									display: block;
									width: 100%;
								}
							}
						}

						&:nth-child(even) {
							td {
								position: relative;

								&:last-child {
									&:after {
										content: "";
										display: inline-block;
										width: 5px;
										height: 100%;
										position: absolute;
										right: 0;
										top: 50%;
										background-color: $dark-sky-blue;
										transform: translateY(-50%);
										-webkit-transform: translateY(-50%);
									}
								}
							}
						}
						&:hover {
							td {
								.name-edit {
									.right-detail {
										.action{
											display: block;
										}
									}
								}
							}
						}
					}

				}
			}
		}

		@media screen and (max-width:$mobile-small) {
			padding: 0 23px;
		}
	}
}