.admin-products-main {
	.fileter-block {
		.select-box {
			width: 282px;
			span {
				font-size: 18px;
				line-height: 22px;
				padding: 7px 15px;
			}
			ul {
				top: 37px;
				width: calc(100% + 2px);
				left: -1px;
				li {
					a {
						padding: 7px 15px;
					}
				}
			}
		}
		.search-sort-block {
			.common-btn-blue {
				margin-bottom: 0;
				padding: 5px 15px;
				min-width: 127px;

				span {
					font-size: 18px;
					line-height: 28px;
				}
			}
			@media screen and (max-width:$ipad-portrait) {
				margin: 5px 0 0 auto;
			}
			.mobile-filter {
				margin-left: auto;
			}
		}
	}
	.admin-products-table {
		padding-top: 20px;
		.table-responsive {
			.table {
				thead {
					tr {
						th {
							padding: 18px 14px 4px;

							&:nth-child(1) {
								width: 470px;

								@media screen and (max-width:$large-mx) {
									width: 350px;
								}
							}

							&:nth-child(2) {
								width: 180px;

								@media screen and (max-width:$large-mx) {
									width: 160px;
								}
							}
							&:nth-child(3) {
								width: 200px;

								@media screen and (max-width:$large-mx) {
									width: 170px;
								}
							}
							&:nth-child(4) {
								width: 290px;
								text-align: left;

								@media screen and (max-width:$large-mx) {
									width: 210px;
								}
							}
							&:nth-child(5) {
								width: 180px;
								text-align: right;
								padding-right: 38px;

								@media screen and (max-width:$mobile-small) {
									padding-right: 20px;
								}

								@media screen and (max-width:$large-mx) {
									width: 180px;
									padding-right: 20px;
								}
							}
						}
					}
				}
				tbody {
					tr {
						td {
							padding: 15px 14px 10px;
							font-size: 16px;
							line-height: 18px;
							color: $light-black;
							vertical-align: middle;

							.name-edit {
								width: calc(100% - 18px);

								.img-c {
									width: 62px;
									height: 53px;
								}
								.right-detail {
									width: calc(100% - 62px);
									padding-left: 10px;
									h3 {
										display: inline-block;
										color: $light-black;
										font-size: 16px;
										line-height: 20px;
									}
									.action {
										display: none;
										a {
											color: $panton-gray;
											font-size: 14px;
										}
									}

									> a {
										font-size: 16px;
										line-height: 20px;
										color: $light-black;
										text-decoration: none;
										font-family: $arial;
										font-weight: $font-normal;
										padding-left: 15px;
										position: relative;

										&:hover {
											color: $sky-blue;
										}

										&:after {
											content: "";
											display: inline-block;
											width: 1px;
											height: 18px;
											background-color: $light-black;
											position: absolute;
											top: 50%;
											transform: translateY(-50%);
											-webkit-transform: translateY(-50%);
											left: 6px;
										}
									}
								}
							}
							&:nth-child(2) {
								line-height: 20px;
							}

							&:nth-child(3) {
								span {
									width: 100%;
									display: block;
								}
							}
							&:nth-child(5) {
								text-align: right;
								padding-right: 42px;

								@media screen and (max-width:$large-mx) {
									padding-right: 20px;
								}

							}

							.checkbox-cust {
								label {
									background-color: $white;
								}
							}
						}
						&:nth-child(even) {
							background-color: $off-white;
						}
						&:nth-child(1) {
							td {
								padding-top: 22px;
							}
						}
						&:hover {
							td {
								.name-edit {
									.right-detail {
										.action {
											display: block;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		@media screen and (max-width:$mobile-small) {
			padding: 0 23px;
		}
	}
}