.contracts-main {
	.fileter-block {
		@media screen and (max-width:$ipad-portrait) {
			justify-content: space-between;
		}

		.select-box {
			width: 282px;

			@media screen and (max-width:$large-mx) {
				width: 220px;
			}

			@media screen and (max-width:$ipad-portrait) {
				width: calc(50% - 10px);
			}

			&:first-of-type {
				margin-right: 19px;

				@media screen and (max-width:$large-mx) {
					margin-right: 10px;
				}
			}
		}
	}

	.contracts-list {
		margin-top: 80px;
		margin-left: -30px;
		margin-right: -30px;

		@media screen and (max-width:$large-m) {
			margin-top: 60px;
			margin-left: -15px;
			margin-right: -15px;
		}

		@media screen and (max-width:$mobile-small) {
			margin-left: 0;
			margin-right: 0;
			padding: 0 10px;
		}

		.contracts-box {
			width: 50%;
			padding: 30px;

			> div {
				position: relative;

				.image-block {
					width: 46%;
					position: relative;

					img {
						width: auto;
						max-width: 170px;
						position: absolute;
						top: -28px;
						left: 45px;

						@media screen and (max-width:$large) {
							left: 6px;
							max-width: 140px;
						}

						@media screen and (max-width:$mobile-small) {
							right: 40px;
							max-width: 135px;
							left: auto;
							top: -38px;
						}

						@media screen and (max-width:$small-x) {
							right: 10px;
							max-width: 125px;
							top: -28px;
						}
					}

					@media screen and (max-width:$mobile-small) {
						order: 2;
					}

					&:after {
						content: "";
						display: inline-block;
						width: 2px;
						height: 67%;
						background-color: $off-white;
						position: absolute;
						right: 32px;
						top: 50%;
						transform: translateY(-50%);
						-webkit-transform: translateY(-50%);

						@media screen and (max-width:$large-m) {
							right: 10px;
						}

						@media screen and (max-width:$ipad-landscape) {
							right: 5px;
						}

						@media screen and (max-width:$ipad-portrait) {
							right: 35px;
						}

						@media screen and (max-width:$mobile-small) {
							display: none;
						}
					}
				}

				.contracts-content {
					width: 54%;
					position: relative;
					padding: 33px 5px;
					overflow: hidden;

					h3 {
						font-size: 22px;
						line-height: 28px;
						color: $cobalt-blue;
						font-family: $gibson;
						font-weight: $font-semi-bold;
						margin: 10px 0 2px;
						text-decoration: none;
						@include transition(all 0.3s ease-in-out);

						@media screen and (max-width:$large-m) {
							font-size: 18px;
						}

						@media screen and (max-width:$mobile-small) {
							font-size: 16px;
							margin-bottom: 0;
							margin-top: 5px;
						}

					}

					h4 {
						margin: 0;
						font-size: 18px;
						line-height: 22px;
						color: $black;
						font-family: $gibson;
						font-weight: $font-normal;

						@media screen and (max-width:$mobile-small) {
							font-size: 14px;
						}
					}

					.date {
						font-size: 14px;
						line-height: 18px;
						color: $panton-gray;
						font-family: $arial;
						font-weight: $font-normal;
						margin-top: 12px;

						@media screen and (max-width:$mobile-small) {
							font-size: 12px;
							padding-top: 0;
							line-height: 18px;
						}
					}

					&:after {
						content: "";
						display: block;
						width: 150px;
						height: 150px;
						opacity: 0.14;
						position: absolute;
						background-image: url(#{$imgpath}/ic_gear.svg);
						background-size: contain;
						background-repeat: no-repeat;
						background-position: right bottom;
						right: -34px;
						top: 78px;

						@media screen and (max-width:$mobile-small) {
							display: none;
						}
					}

					@media screen and (max-width:$mobile-small) {
						order: 1;
						padding: 13px 20px;
					}

					> img {
						@media screen and (max-width:$mobile-small) {
							width: 24px;
						}
					}

				}

				&:after {
					content: "";
					position: absolute;
					width: 100%;
					height: 5px;
					background-color: $sky-blue;
					bottom: 0;
					left: 0;
					right: 0;
				}

				&:before {
					content: "";
					position: absolute;
					width: 100%;
					height: 0;
					background-color: $cobalt-blue;
					bottom: 0;
					left: 0;
					right: 0;
					@include transition(all 0.2s ease-in-out);
					z-index: 1;
				}

				&:hover {
					&:before {
						height: 5px;
					}
				}
			}

			@media screen and (max-width:$large-m) {
				padding: 25px 15px;
			}
			@media screen and (max-width:$mobile-small) {
				padding: 0 15px;
			}
			@media screen and (max-width:$ipad-portrait) {
				width: 100%;

				&:not(:last-child) {
					margin-bottom: 56px;
				}
			}
		}

		.data-empty {
			padding: 0 15px;
			font-size: 22px;
			line-height: 28px;
			color: $black;
			font-family: $gibson;
			font-weight: $font-semi-bold;
			text-align: center;
			display: block;
    		margin: 0 auto;
		}
	}
}